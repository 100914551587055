import { default as ad_45activitiesvDnsNGHBYLMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/ad-activities.vue?macro=true";
import { default as ai_45marketerRhWuPnkI1GMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/ai-marketer.vue?macro=true";
import { default as managementdYAhfmz9YfMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/audience/management.vue?macro=true";
import { default as indexChHAULWa5VMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/audience/new/index.vue?macro=true";
import { default as lookalikejZSYw7HeEkMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/audience/new/lookalike.vue?macro=true";
import { default as mixedstEqMrjHjgMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/audience/new/mixed.vue?macro=true";
import { default as sdkwbGhfWz9wHMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/audience/new/sdk.vue?macro=true";
import { default as smart_45contractXAMCLqSyxVMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/audience/new/smart-contract.vue?macro=true";
import { default as uploadc1g7bud9WvMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/audience/new/upload.vue?macro=true";
import { default as usercoGitEmIqQMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/audience/user.vue?macro=true";
import { default as ad_45groups9F7qvwTkpLMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/campaigns/ad-groups.vue?macro=true";
import { default as adsWzKcnFDmQOMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/campaigns/ads.vue?macro=true";
import { default as listvjy4MO0LgxMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/campaigns/list.vue?macro=true";
import { default as campaignsaw8lWiuq29Meta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/campaigns.vue?macro=true";
import { default as _91_91id_93_93Ssm8iEf1m2Meta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/channels/[[id]].vue?macro=true";
import { default as channelsYNSqYZMPFwMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/channels.vue?macro=true";
import { default as creatives2GMK2x4wEgMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/creatives.vue?macro=true";
import { default as funnelM7QBfjRvSjMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/dashboard/funnel.vue?macro=true";
import { default as ltvLR4tHCMYPWMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/dashboard/ltv.vue?macro=true";
import { default as new_45usersGHiqIslJCXMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/dashboard/new-users.vue?macro=true";
import { default as retention7sVSr5gDlGMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/dashboard/retention.vue?macro=true";
import { default as revenue_45spenddNvu1EquJ2Meta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/dashboard/revenue-spend.vue?macro=true";
import { default as event_45forwardingxx4qwKFe1iMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/event-forwarding.vue?macro=true";
import { default as iap_45subscriptionYVLpzFohfvMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/iap-subscription.vue?macro=true";
import { default as latest_45builds7KxVZfTizZMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/latest-builds.vue?macro=true";
import { default as linksiJzSlcSkipMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/links.vue?macro=true";
import { default as create2LUfc8enyqMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/media-buying-plan/create.vue?macro=true";
import { default as placementsyC5w8aO0YKMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/placements.vue?macro=true";
import { default as _91id_93S0t7I0RGEBMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/project/[id].vue?macro=true";
import { default as managementh8YGYd39NXMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/project/management.vue?macro=true";
import { default as newGfYD720BAjMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/project/new.vue?macro=true";
import { default as detailsOpWlRPpiExMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/quest/details.vue?macro=true";
import { default as editTxRY6FoV7XMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/quest/edit.vue?macro=true";
import { default as new60GQyXrQcrMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/quest/new.vue?macro=true";
import { default as archivedZTOwjmLu4tMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/quests/archived.vue?macro=true";
import { default as listUb22EtC59GMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/quests/list.vue?macro=true";
import { default as questsQXp67lJ4UXMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/quests.vue?macro=true";
import { default as remote_45appb4UQr0SMydMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/remote-app.vue?macro=true";
import { default as remote_45configLzLsLYBH6mMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/remote-config.vue?macro=true";
import { default as basicpmEQPHZcm9Meta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/settings/basic.vue?macro=true";
import { default as history01JlEOjxx8Meta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/settings/history.vue?macro=true";
import { default as settingsQo44ZAEjXCMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/settings.vue?macro=true";
import { default as _91id_93dPWnzQBh2gMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/user/[id].vue?macro=true";
import { default as _91_91orgId_93_93lNZJfCGbLsMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[orgId]].vue?macro=true";
import { default as admin_45loginrTbjuTbUoPMeta } from "/opt/atlassian/pipelines/agent/build/pages/admin-login.vue?macro=true";
import { default as blankwVAx0fdoHgMeta } from "/opt/atlassian/pipelines/agent/build/pages/blank.vue?macro=true";
import { default as forgot_45passwordLVWvp1iivhMeta } from "/opt/atlassian/pipelines/agent/build/pages/forgot-password.vue?macro=true";
import { default as indexOD9t3F2bSJMeta } from "/opt/atlassian/pipelines/agent/build/pages/index.vue?macro=true";
import { default as invitationa529cQKQBKMeta } from "/opt/atlassian/pipelines/agent/build/pages/invitation.vue?macro=true";
import { default as logineaOYVOrhPDMeta } from "/opt/atlassian/pipelines/agent/build/pages/login.vue?macro=true";
import { default as new_45passwordWu7z47IBPLMeta } from "/opt/atlassian/pipelines/agent/build/pages/new-password.vue?macro=true";
import { default as paymentQ0BRCfGii6Meta } from "/opt/atlassian/pipelines/agent/build/pages/payment.vue?macro=true";
import { default as registerQphDd3L40XMeta } from "/opt/atlassian/pipelines/agent/build/pages/register.vue?macro=true";
import { default as registration_45verifyvwyoXwNqZLMeta } from "/opt/atlassian/pipelines/agent/build/pages/registration-verify.vue?macro=true";
import { default as reset_45passwordHNP2zWIISjMeta } from "/opt/atlassian/pipelines/agent/build/pages/reset-password.vue?macro=true";
import { default as twitter_oauthlPKRwiCcRcMeta } from "/opt/atlassian/pipelines/agent/build/pages/twitter_oauth.vue?macro=true";
import { default as user_45profileGjP71ePpNTMeta } from "/opt/atlassian/pipelines/agent/build/pages/user-profile.vue?macro=true";
export default [
  {
    name: _91_91orgId_93_93lNZJfCGbLsMeta?.name ?? "orgId",
    path: _91_91orgId_93_93lNZJfCGbLsMeta?.path ?? "/:orgId?",
    meta: _91_91orgId_93_93lNZJfCGbLsMeta || {},
    alias: _91_91orgId_93_93lNZJfCGbLsMeta?.alias || [],
    redirect: _91_91orgId_93_93lNZJfCGbLsMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]].vue").then(m => m.default || m),
    children: [
  {
    name: ad_45activitiesvDnsNGHBYLMeta?.name ?? "orgId-ad-activities",
    path: ad_45activitiesvDnsNGHBYLMeta?.path ?? "ad-activities",
    meta: ad_45activitiesvDnsNGHBYLMeta || {},
    alias: ad_45activitiesvDnsNGHBYLMeta?.alias || [],
    redirect: ad_45activitiesvDnsNGHBYLMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/ad-activities.vue").then(m => m.default || m)
  },
  {
    name: ai_45marketerRhWuPnkI1GMeta?.name ?? "orgId-ai-marketer",
    path: ai_45marketerRhWuPnkI1GMeta?.path ?? "ai-marketer",
    meta: ai_45marketerRhWuPnkI1GMeta || {},
    alias: ai_45marketerRhWuPnkI1GMeta?.alias || [],
    redirect: ai_45marketerRhWuPnkI1GMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/ai-marketer.vue").then(m => m.default || m)
  },
  {
    name: managementdYAhfmz9YfMeta?.name ?? "orgId-audience-management",
    path: managementdYAhfmz9YfMeta?.path ?? "audience/management",
    meta: managementdYAhfmz9YfMeta || {},
    alias: managementdYAhfmz9YfMeta?.alias || [],
    redirect: managementdYAhfmz9YfMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/audience/management.vue").then(m => m.default || m)
  },
  {
    name: indexChHAULWa5VMeta?.name ?? "orgId-audience-new",
    path: indexChHAULWa5VMeta?.path ?? "audience/new",
    meta: indexChHAULWa5VMeta || {},
    alias: indexChHAULWa5VMeta?.alias || [],
    redirect: indexChHAULWa5VMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/audience/new/index.vue").then(m => m.default || m)
  },
  {
    name: lookalikejZSYw7HeEkMeta?.name ?? "orgId-audience-new-lookalike",
    path: lookalikejZSYw7HeEkMeta?.path ?? "audience/new/lookalike",
    meta: lookalikejZSYw7HeEkMeta || {},
    alias: lookalikejZSYw7HeEkMeta?.alias || [],
    redirect: lookalikejZSYw7HeEkMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/audience/new/lookalike.vue").then(m => m.default || m)
  },
  {
    name: mixedstEqMrjHjgMeta?.name ?? "orgId-audience-new-mixed",
    path: mixedstEqMrjHjgMeta?.path ?? "audience/new/mixed",
    meta: mixedstEqMrjHjgMeta || {},
    alias: mixedstEqMrjHjgMeta?.alias || [],
    redirect: mixedstEqMrjHjgMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/audience/new/mixed.vue").then(m => m.default || m)
  },
  {
    name: sdkwbGhfWz9wHMeta?.name ?? "orgId-audience-new-sdk",
    path: sdkwbGhfWz9wHMeta?.path ?? "audience/new/sdk",
    meta: sdkwbGhfWz9wHMeta || {},
    alias: sdkwbGhfWz9wHMeta?.alias || [],
    redirect: sdkwbGhfWz9wHMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/audience/new/sdk.vue").then(m => m.default || m)
  },
  {
    name: smart_45contractXAMCLqSyxVMeta?.name ?? "orgId-audience-new-smart-contract",
    path: smart_45contractXAMCLqSyxVMeta?.path ?? "audience/new/smart-contract",
    meta: smart_45contractXAMCLqSyxVMeta || {},
    alias: smart_45contractXAMCLqSyxVMeta?.alias || [],
    redirect: smart_45contractXAMCLqSyxVMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/audience/new/smart-contract.vue").then(m => m.default || m)
  },
  {
    name: uploadc1g7bud9WvMeta?.name ?? "orgId-audience-new-upload",
    path: uploadc1g7bud9WvMeta?.path ?? "audience/new/upload",
    meta: uploadc1g7bud9WvMeta || {},
    alias: uploadc1g7bud9WvMeta?.alias || [],
    redirect: uploadc1g7bud9WvMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/audience/new/upload.vue").then(m => m.default || m)
  },
  {
    name: usercoGitEmIqQMeta?.name ?? "orgId-audience-user",
    path: usercoGitEmIqQMeta?.path ?? "audience/user",
    meta: usercoGitEmIqQMeta || {},
    alias: usercoGitEmIqQMeta?.alias || [],
    redirect: usercoGitEmIqQMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/audience/user.vue").then(m => m.default || m)
  },
  {
    name: campaignsaw8lWiuq29Meta?.name ?? "orgId-campaigns",
    path: campaignsaw8lWiuq29Meta?.path ?? "campaigns",
    meta: campaignsaw8lWiuq29Meta || {},
    alias: campaignsaw8lWiuq29Meta?.alias || [],
    redirect: campaignsaw8lWiuq29Meta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/campaigns.vue").then(m => m.default || m),
    children: [
  {
    name: ad_45groups9F7qvwTkpLMeta?.name ?? "orgId-campaigns-ad-groups",
    path: ad_45groups9F7qvwTkpLMeta?.path ?? "ad-groups",
    meta: ad_45groups9F7qvwTkpLMeta || {},
    alias: ad_45groups9F7qvwTkpLMeta?.alias || [],
    redirect: ad_45groups9F7qvwTkpLMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/campaigns/ad-groups.vue").then(m => m.default || m)
  },
  {
    name: adsWzKcnFDmQOMeta?.name ?? "orgId-campaigns-ads",
    path: adsWzKcnFDmQOMeta?.path ?? "ads",
    meta: adsWzKcnFDmQOMeta || {},
    alias: adsWzKcnFDmQOMeta?.alias || [],
    redirect: adsWzKcnFDmQOMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/campaigns/ads.vue").then(m => m.default || m)
  },
  {
    name: listvjy4MO0LgxMeta?.name ?? "orgId-campaigns-list",
    path: listvjy4MO0LgxMeta?.path ?? "list",
    meta: listvjy4MO0LgxMeta || {},
    alias: listvjy4MO0LgxMeta?.alias || [],
    redirect: listvjy4MO0LgxMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/campaigns/list.vue").then(m => m.default || m)
  }
]
  },
  {
    name: channelsYNSqYZMPFwMeta?.name ?? "orgId-channels",
    path: channelsYNSqYZMPFwMeta?.path ?? "channels",
    meta: channelsYNSqYZMPFwMeta || {},
    alias: channelsYNSqYZMPFwMeta?.alias || [],
    redirect: channelsYNSqYZMPFwMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/channels.vue").then(m => m.default || m),
    children: [
  {
    name: _91_91id_93_93Ssm8iEf1m2Meta?.name ?? "orgId-channels-id",
    path: _91_91id_93_93Ssm8iEf1m2Meta?.path ?? ":id?",
    meta: _91_91id_93_93Ssm8iEf1m2Meta || {},
    alias: _91_91id_93_93Ssm8iEf1m2Meta?.alias || [],
    redirect: _91_91id_93_93Ssm8iEf1m2Meta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/channels/[[id]].vue").then(m => m.default || m)
  }
]
  },
  {
    name: creatives2GMK2x4wEgMeta?.name ?? "orgId-creatives",
    path: creatives2GMK2x4wEgMeta?.path ?? "creatives",
    meta: creatives2GMK2x4wEgMeta || {},
    alias: creatives2GMK2x4wEgMeta?.alias || [],
    redirect: creatives2GMK2x4wEgMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/creatives.vue").then(m => m.default || m)
  },
  {
    name: funnelM7QBfjRvSjMeta?.name ?? "orgId-dashboard-funnel",
    path: funnelM7QBfjRvSjMeta?.path ?? "dashboard/funnel",
    meta: funnelM7QBfjRvSjMeta || {},
    alias: funnelM7QBfjRvSjMeta?.alias || [],
    redirect: funnelM7QBfjRvSjMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/dashboard/funnel.vue").then(m => m.default || m)
  },
  {
    name: ltvLR4tHCMYPWMeta?.name ?? "orgId-dashboard-ltv",
    path: ltvLR4tHCMYPWMeta?.path ?? "dashboard/ltv",
    meta: ltvLR4tHCMYPWMeta || {},
    alias: ltvLR4tHCMYPWMeta?.alias || [],
    redirect: ltvLR4tHCMYPWMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/dashboard/ltv.vue").then(m => m.default || m)
  },
  {
    name: new_45usersGHiqIslJCXMeta?.name ?? "orgId-dashboard-new-users",
    path: new_45usersGHiqIslJCXMeta?.path ?? "dashboard/new-users",
    meta: new_45usersGHiqIslJCXMeta || {},
    alias: new_45usersGHiqIslJCXMeta?.alias || [],
    redirect: new_45usersGHiqIslJCXMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/dashboard/new-users.vue").then(m => m.default || m)
  },
  {
    name: retention7sVSr5gDlGMeta?.name ?? "orgId-dashboard-retention",
    path: retention7sVSr5gDlGMeta?.path ?? "dashboard/retention",
    meta: retention7sVSr5gDlGMeta || {},
    alias: retention7sVSr5gDlGMeta?.alias || [],
    redirect: retention7sVSr5gDlGMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/dashboard/retention.vue").then(m => m.default || m)
  },
  {
    name: revenue_45spenddNvu1EquJ2Meta?.name ?? "orgId-dashboard-revenue-spend",
    path: revenue_45spenddNvu1EquJ2Meta?.path ?? "dashboard/revenue-spend",
    meta: revenue_45spenddNvu1EquJ2Meta || {},
    alias: revenue_45spenddNvu1EquJ2Meta?.alias || [],
    redirect: revenue_45spenddNvu1EquJ2Meta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/dashboard/revenue-spend.vue").then(m => m.default || m)
  },
  {
    name: event_45forwardingxx4qwKFe1iMeta?.name ?? "orgId-event-forwarding",
    path: event_45forwardingxx4qwKFe1iMeta?.path ?? "event-forwarding",
    meta: event_45forwardingxx4qwKFe1iMeta || {},
    alias: event_45forwardingxx4qwKFe1iMeta?.alias || [],
    redirect: event_45forwardingxx4qwKFe1iMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/event-forwarding.vue").then(m => m.default || m)
  },
  {
    name: iap_45subscriptionYVLpzFohfvMeta?.name ?? "orgId-iap-subscription",
    path: iap_45subscriptionYVLpzFohfvMeta?.path ?? "iap-subscription",
    meta: iap_45subscriptionYVLpzFohfvMeta || {},
    alias: iap_45subscriptionYVLpzFohfvMeta?.alias || [],
    redirect: iap_45subscriptionYVLpzFohfvMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/iap-subscription.vue").then(m => m.default || m)
  },
  {
    name: latest_45builds7KxVZfTizZMeta?.name ?? "orgId-latest-builds",
    path: latest_45builds7KxVZfTizZMeta?.path ?? "latest-builds",
    meta: latest_45builds7KxVZfTizZMeta || {},
    alias: latest_45builds7KxVZfTizZMeta?.alias || [],
    redirect: latest_45builds7KxVZfTizZMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/latest-builds.vue").then(m => m.default || m)
  },
  {
    name: linksiJzSlcSkipMeta?.name ?? "orgId-links",
    path: linksiJzSlcSkipMeta?.path ?? "links",
    meta: linksiJzSlcSkipMeta || {},
    alias: linksiJzSlcSkipMeta?.alias || [],
    redirect: linksiJzSlcSkipMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/links.vue").then(m => m.default || m)
  },
  {
    name: create2LUfc8enyqMeta?.name ?? "orgId-media-buying-plan-create",
    path: create2LUfc8enyqMeta?.path ?? "media-buying-plan/create",
    meta: create2LUfc8enyqMeta || {},
    alias: create2LUfc8enyqMeta?.alias || [],
    redirect: create2LUfc8enyqMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/media-buying-plan/create.vue").then(m => m.default || m)
  },
  {
    name: placementsyC5w8aO0YKMeta?.name ?? "orgId-placements",
    path: placementsyC5w8aO0YKMeta?.path ?? "placements",
    meta: placementsyC5w8aO0YKMeta || {},
    alias: placementsyC5w8aO0YKMeta?.alias || [],
    redirect: placementsyC5w8aO0YKMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/placements.vue").then(m => m.default || m)
  },
  {
    name: _91id_93S0t7I0RGEBMeta?.name ?? "orgId-project-id",
    path: _91id_93S0t7I0RGEBMeta?.path ?? "project/:id()",
    meta: _91id_93S0t7I0RGEBMeta || {},
    alias: _91id_93S0t7I0RGEBMeta?.alias || [],
    redirect: _91id_93S0t7I0RGEBMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/project/[id].vue").then(m => m.default || m)
  },
  {
    name: managementh8YGYd39NXMeta?.name ?? "orgId-project-management",
    path: managementh8YGYd39NXMeta?.path ?? "project/management",
    meta: managementh8YGYd39NXMeta || {},
    alias: managementh8YGYd39NXMeta?.alias || [],
    redirect: managementh8YGYd39NXMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/project/management.vue").then(m => m.default || m)
  },
  {
    name: newGfYD720BAjMeta?.name ?? "orgId-project-new",
    path: newGfYD720BAjMeta?.path ?? "project/new",
    meta: newGfYD720BAjMeta || {},
    alias: newGfYD720BAjMeta?.alias || [],
    redirect: newGfYD720BAjMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/project/new.vue").then(m => m.default || m)
  },
  {
    name: detailsOpWlRPpiExMeta?.name ?? "orgId-quest-details",
    path: detailsOpWlRPpiExMeta?.path ?? "quest/details",
    meta: detailsOpWlRPpiExMeta || {},
    alias: detailsOpWlRPpiExMeta?.alias || [],
    redirect: detailsOpWlRPpiExMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/quest/details.vue").then(m => m.default || m)
  },
  {
    name: editTxRY6FoV7XMeta?.name ?? "orgId-quest-edit",
    path: editTxRY6FoV7XMeta?.path ?? "quest/edit",
    meta: editTxRY6FoV7XMeta || {},
    alias: editTxRY6FoV7XMeta?.alias || [],
    redirect: editTxRY6FoV7XMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/quest/edit.vue").then(m => m.default || m)
  },
  {
    name: new60GQyXrQcrMeta?.name ?? "orgId-quest-new",
    path: new60GQyXrQcrMeta?.path ?? "quest/new",
    meta: new60GQyXrQcrMeta || {},
    alias: new60GQyXrQcrMeta?.alias || [],
    redirect: new60GQyXrQcrMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/quest/new.vue").then(m => m.default || m)
  },
  {
    name: questsQXp67lJ4UXMeta?.name ?? "orgId-quests",
    path: questsQXp67lJ4UXMeta?.path ?? "quests",
    meta: questsQXp67lJ4UXMeta || {},
    alias: questsQXp67lJ4UXMeta?.alias || [],
    redirect: questsQXp67lJ4UXMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/quests.vue").then(m => m.default || m),
    children: [
  {
    name: archivedZTOwjmLu4tMeta?.name ?? "orgId-quests-archived",
    path: archivedZTOwjmLu4tMeta?.path ?? "archived",
    meta: archivedZTOwjmLu4tMeta || {},
    alias: archivedZTOwjmLu4tMeta?.alias || [],
    redirect: archivedZTOwjmLu4tMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/quests/archived.vue").then(m => m.default || m)
  },
  {
    name: listUb22EtC59GMeta?.name ?? "orgId-quests-list",
    path: listUb22EtC59GMeta?.path ?? "list",
    meta: listUb22EtC59GMeta || {},
    alias: listUb22EtC59GMeta?.alias || [],
    redirect: listUb22EtC59GMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/quests/list.vue").then(m => m.default || m)
  }
]
  },
  {
    name: remote_45appb4UQr0SMydMeta?.name ?? "orgId-remote-app",
    path: remote_45appb4UQr0SMydMeta?.path ?? "remote-app",
    meta: remote_45appb4UQr0SMydMeta || {},
    alias: remote_45appb4UQr0SMydMeta?.alias || [],
    redirect: remote_45appb4UQr0SMydMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/remote-app.vue").then(m => m.default || m)
  },
  {
    name: remote_45configLzLsLYBH6mMeta?.name ?? "orgId-remote-config",
    path: remote_45configLzLsLYBH6mMeta?.path ?? "remote-config",
    meta: remote_45configLzLsLYBH6mMeta || {},
    alias: remote_45configLzLsLYBH6mMeta?.alias || [],
    redirect: remote_45configLzLsLYBH6mMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/remote-config.vue").then(m => m.default || m)
  },
  {
    name: settingsQo44ZAEjXCMeta?.name ?? "orgId-settings",
    path: settingsQo44ZAEjXCMeta?.path ?? "settings",
    meta: settingsQo44ZAEjXCMeta || {},
    alias: settingsQo44ZAEjXCMeta?.alias || [],
    redirect: settingsQo44ZAEjXCMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/settings.vue").then(m => m.default || m),
    children: [
  {
    name: basicpmEQPHZcm9Meta?.name ?? "orgId-settings-basic",
    path: basicpmEQPHZcm9Meta?.path ?? "basic",
    meta: basicpmEQPHZcm9Meta || {},
    alias: basicpmEQPHZcm9Meta?.alias || [],
    redirect: basicpmEQPHZcm9Meta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/settings/basic.vue").then(m => m.default || m)
  },
  {
    name: history01JlEOjxx8Meta?.name ?? "orgId-settings-history",
    path: history01JlEOjxx8Meta?.path ?? "history",
    meta: history01JlEOjxx8Meta || {},
    alias: history01JlEOjxx8Meta?.alias || [],
    redirect: history01JlEOjxx8Meta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/settings/history.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_93dPWnzQBh2gMeta?.name ?? "orgId-user-id",
    path: _91id_93dPWnzQBh2gMeta?.path ?? "user/:id()",
    meta: _91id_93dPWnzQBh2gMeta || {},
    alias: _91id_93dPWnzQBh2gMeta?.alias || [],
    redirect: _91id_93dPWnzQBh2gMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[orgId]]/user/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: admin_45loginrTbjuTbUoPMeta?.name ?? "admin-login",
    path: admin_45loginrTbjuTbUoPMeta?.path ?? "/admin-login",
    meta: admin_45loginrTbjuTbUoPMeta || {},
    alias: admin_45loginrTbjuTbUoPMeta?.alias || [],
    redirect: admin_45loginrTbjuTbUoPMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/admin-login.vue").then(m => m.default || m)
  },
  {
    name: blankwVAx0fdoHgMeta?.name ?? "blank",
    path: blankwVAx0fdoHgMeta?.path ?? "/blank",
    meta: blankwVAx0fdoHgMeta || {},
    alias: blankwVAx0fdoHgMeta?.alias || [],
    redirect: blankwVAx0fdoHgMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/blank.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordLVWvp1iivhMeta?.name ?? "forgot-password",
    path: forgot_45passwordLVWvp1iivhMeta?.path ?? "/forgot-password",
    meta: forgot_45passwordLVWvp1iivhMeta || {},
    alias: forgot_45passwordLVWvp1iivhMeta?.alias || [],
    redirect: forgot_45passwordLVWvp1iivhMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: indexOD9t3F2bSJMeta?.name ?? "index",
    path: indexOD9t3F2bSJMeta?.path ?? "/",
    meta: indexOD9t3F2bSJMeta || {},
    alias: indexOD9t3F2bSJMeta?.alias || [],
    redirect: indexOD9t3F2bSJMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue").then(m => m.default || m)
  },
  {
    name: invitationa529cQKQBKMeta?.name ?? "invitation",
    path: invitationa529cQKQBKMeta?.path ?? "/invitation",
    meta: invitationa529cQKQBKMeta || {},
    alias: invitationa529cQKQBKMeta?.alias || [],
    redirect: invitationa529cQKQBKMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/invitation.vue").then(m => m.default || m)
  },
  {
    name: logineaOYVOrhPDMeta?.name ?? "login",
    path: logineaOYVOrhPDMeta?.path ?? "/login",
    meta: logineaOYVOrhPDMeta || {},
    alias: logineaOYVOrhPDMeta?.alias || [],
    redirect: logineaOYVOrhPDMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/login.vue").then(m => m.default || m)
  },
  {
    name: new_45passwordWu7z47IBPLMeta?.name ?? "new-password",
    path: new_45passwordWu7z47IBPLMeta?.path ?? "/new-password",
    meta: new_45passwordWu7z47IBPLMeta || {},
    alias: new_45passwordWu7z47IBPLMeta?.alias || [],
    redirect: new_45passwordWu7z47IBPLMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/new-password.vue").then(m => m.default || m)
  },
  {
    name: paymentQ0BRCfGii6Meta?.name ?? "payment",
    path: paymentQ0BRCfGii6Meta?.path ?? "/payment",
    meta: paymentQ0BRCfGii6Meta || {},
    alias: paymentQ0BRCfGii6Meta?.alias || [],
    redirect: paymentQ0BRCfGii6Meta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/payment.vue").then(m => m.default || m)
  },
  {
    name: registerQphDd3L40XMeta?.name ?? "register",
    path: registerQphDd3L40XMeta?.path ?? "/register",
    meta: registerQphDd3L40XMeta || {},
    alias: registerQphDd3L40XMeta?.alias || [],
    redirect: registerQphDd3L40XMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registration_45verifyvwyoXwNqZLMeta?.name ?? "registration-verify",
    path: registration_45verifyvwyoXwNqZLMeta?.path ?? "/registration-verify",
    meta: registration_45verifyvwyoXwNqZLMeta || {},
    alias: registration_45verifyvwyoXwNqZLMeta?.alias || [],
    redirect: registration_45verifyvwyoXwNqZLMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/registration-verify.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordHNP2zWIISjMeta?.name ?? "reset-password",
    path: reset_45passwordHNP2zWIISjMeta?.path ?? "/reset-password",
    meta: reset_45passwordHNP2zWIISjMeta || {},
    alias: reset_45passwordHNP2zWIISjMeta?.alias || [],
    redirect: reset_45passwordHNP2zWIISjMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: twitter_oauthlPKRwiCcRcMeta?.name ?? "twitter_oauth",
    path: twitter_oauthlPKRwiCcRcMeta?.path ?? "/twitter_oauth",
    meta: twitter_oauthlPKRwiCcRcMeta || {},
    alias: twitter_oauthlPKRwiCcRcMeta?.alias || [],
    redirect: twitter_oauthlPKRwiCcRcMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/twitter_oauth.vue").then(m => m.default || m)
  },
  {
    name: user_45profileGjP71ePpNTMeta?.name ?? "user-profile",
    path: user_45profileGjP71ePpNTMeta?.path ?? "/user-profile",
    meta: user_45profileGjP71ePpNTMeta || {},
    alias: user_45profileGjP71ePpNTMeta?.alias || [],
    redirect: user_45profileGjP71ePpNTMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/user-profile.vue").then(m => m.default || m)
  }
]